<template>
  <div>
    <div class="o-countdown" v-if="countdownReady" :style="`--num-countdown-units: ${numCountdownUnits}`">
      <div class="o-countdown__unit o-countdown__unit--days" v-if="countdown.days">
        <span id="cntdn-days" class="o-countdown__value">{{ String(countdown.days).padStart(2, '0') }}</span>
        <span class="o-countdown__label">Days</span>
      </div>
      <div class="o-countdown__unit o-countdown__unit--hours" v-if="countdown.days || countdown.hours">
        <span id="cntdn-hours" class="o-countdown__value">{{ String(countdown.hours).padStart(2, '0') }}</span>
        <span class="o-countdown__label">Hours</span>
      </div>
      <div class="o-countdown__unit o-countdown__unit--mins"
        v-if="countdown.days || countdown.hours || countdown.minutes">
        <span id="cntdn-minutes" class="o-countdown__value">{{ String(countdown.minutes).padStart(2, '0') }}</span>
        <span class="o-countdown__label">Mins</span>
      </div>
      <div class="o-countdown__unit o-countdown__unit--secs" v-if="countdown.minutes">
        <span id="cntdn-seconds" class="o-countdown__value">{{ String(countdown.seconds).padStart(2, '0') }}</span>
        <span class="o-countdown__label">Secs</span>
      </div>
    </div>
    <div v-else>
      <LoadingSpinner />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import LoadingSpinner from './LoadingSpinner.vue';

const props = defineProps<{
  countdownTo: string;
}>();

const countdown = ref({
  days: 0, hours: 0, minutes: 0, seconds: 0
});

const countdownReady = ref(false);
const numCountdownUnits = ref(4);

let countdownInterval;

onBeforeMount(() => {
  countdownInterval = setInterval(() => {
    const now = new Date().getTime();
    const distance = Date.parse(props.countdownTo) - now;

    if (distance < 0) {
      clearInterval(countdownInterval);
      countdownReady.value = false;
      return;
    }

    countdown.value.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    countdown.value.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    countdown.value.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    countdown.value.seconds = Math.floor((distance % (1000 * 60)) / 1000);

    countdownReady.value = true;

    if (countdown.value.days === 0) {
      numCountdownUnits.value = 3;
    }
    if (countdown.value.days === 0 && countdown.value.hours === 0) {
      numCountdownUnits.value = 2;
    }
    if (countdown.value.days === 0 && countdown.value.hours === 0 && countdown.value.minutes === 0) {
      numCountdownUnits.value = 1;
    }
  }, 1000);
});

onBeforeUnmount(() => {
  clearInterval(countdownInterval);
});

</script>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.o-countdown {
  display: flex;
  justify-content: center;
  width: calc(var(--num-countdown-units) * 6rem);

  &__unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    position: relative;
    width: 6rem;
    flex-shrink: 0;

    &--days {
      --gradient-start: var(--g-color-primary);
      --gradient-end: #117cb8;
    }

    &--hours {
      --gradient-start: #117cb8;
      --gradient-end: #0c8ac0;
    }

    &--mins {
      --gradient-start: #0c8ac0;
      --gradient-end: #059aca;
    }

    &--secs {
      --gradient-start: #059aca;
      --gradient-end: var(--g-color-secondary);
    }
  }

  &__value {
    @include m.h1-bold;
    line-height: 1;
    margin: 0;
    padding: 0;
    text-align: center;
    @include m.gradient-text;
  }

  &__label {
    @include m.p1-bold;
    text-transform: uppercase;
    margin-top: -1rem;
    background: linear-gradient(90deg, var(--gradient-start), var(--gradient-end));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
  }

  @media (width < m.$phablet) {
    width: calc(var(--num-countdown-units) * 5rem);

    &__unit {
      width: 5rem;
    }
  }
}
</style>
