<template>
  <a :href="linkTo" type="button" class="o-button">
    <span class="o-button__text">
      <slot></slot>
    </span>

    <div class="o-button__icon" v-if="slots.icon">
      <slot name="icon"></slot>
    </div>
  </a>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';

const slots = useSlots();

interface Props {
  linkTo?: string,
  secondary?: boolean,
  transparent?: boolean,
}

const props = withDefaults(defineProps<Props>(), { secondary: false });

const buttonStyle = computed(() => {
  return props.secondary ? 'o-button--secondary' : '';
});
</script>

<style lang="scss"></style>
