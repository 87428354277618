import { ROVERS_TEAM_IDS } from '../constants'
import { urlify } from '@/common/helpers/url-helpers';

export const getCDNPlayerImgPath = playerId => {
  if (playerId) {
    return `/media/images/players/${playerId}`
  }
}

export const getCDNTeamLogoPath = teamId => {
  if (teamId) {
    return `/media/images/teams/${teamId}`
  }
}

export const getCDNCompLogoPath = compId => {
  if (compId) {
    return `/media/images/leagues/${compId}`
  }
}

export const getCDNStadiumImgPath = (stadiumId) => {
    if (!stadiumId) {
        return '';
    }

    if (stadiumId === 500) { // Ewood Park
        return `/images/stadiums/ewood-park.jpg`
    } else {
        return `/media/images/venues/${stadiumId}`
    }
}

export const getPlayerName = player => {
    if (!player) {
        return '';
    }

    return player.displayName ? player.displayName : player.name
}

export const getTeamName = team => {
    if (!team) {
        return '';
    }

  if (team.id === 67) {
    return 'Rovers'
  }
  return team.name
}

export const getMatchModifierClass = status => {
  switch (status) {
    case 'U':
      return '--upcoming'
      break
    case 'L':
      return '--live'
      break
    case 'C':
      return '--completed'
      break
    default:
      return ''
      break
  }
}

export const getMatchThemeClasses = match => {
  let classes = ''

  if (getMatchStatus(match.fixture) === 'L') {
    classes += ' t-live-match'
  }

  classes += ` t-${match.league.name.toLowerCase().replace(' ', '-')}`

  return classes
}

export const getStatPercentageString = ( total, accurate ) => {
  if (!total ) {
    return '0';
  }
  const accuracy = Math.round((accurate / total) * 100);
  return `${accurate || 0}/${total} (${accuracy}%)`;
}

export const getMatchStatus = fixture=> {
  switch (fixture.status.short) {
    case 'TBD': // Time To Be Defined - Scheduled but date and time are not known
    case 'NS': // Not Started
      return 'U' // Upcoming
      break
    case '1H': // Live - First half in play
    case 'HT': // Halftime
    case '2H': // live - Second half in play
    case 'ET': // Extra Time - Extra time in play
    case 'BT': // Break Time - Break during extra time
    case 'P': // Penalty Shootout - Penalties in play
    case 'SUSP': // Match Suspended - Suspended by referee's decision, may be rescheduled another day
    case 'INT': // Match Interrupted - Interrupted by referee's decision, should resume in a few minutes
    case 'LIVE': // In Progress - Used in very rare cases. It indicates a fixture in progress but the data indicating the half-time or elapsed time are not available
      return 'L' // Live
      break
    case 'FT': // Match Finished - Finished in the regular time
    case 'AET': // Match Finished - Finished after extra time without going to the penalty shootout
    case 'PEN': // Match Finished - Finished after the penalty shootout
    case 'PST': // Match Postponed - Postponed to another day, once the new date and time is known the status will change to Not Started
    case 'CANC': // Match Cancelled - Cancelled, match will not be played
    case 'ABD': // Match Abandoned - Abandoned for various reasons (Bad Weather, Safety, Floodlights, Playing Staff Or Referees), Can be rescheduled or not, it depends on the competition
    case 'AWD': // Technical Loss - Not Played
    case 'WO': // WalkOver - Victory by forfeit or absence of competitor
      return 'C' // Completed
      break
    default:
      return status
      break
  }
}

export const getMatchState = status => {
  switch (status) {
    case 'TBD':
      return 'TBC'
      break
    case 'NS':
      return 'Upcoming'
      break
    case '1H':
      return 'First Half'
      break
    case 'HT':
      return 'HT'
      break
    case '2H':
      return 'Second Half'
      break
    case 'ET':
      return 'Extra Time'
      break
    case 'BT':
      return 'HT ET'
      break
    case 'P':
      return 'Penalty Shootout'
      break
    case 'SUSP':
      return 'Suspended'
      break
    case 'INT':
      return 'Interrupted'
      break
    case 'LIVE':
      return 'Live'
      break
    case 'FT':
      return 'FT'
      break
    case 'AET':
      return 'AET'
      break
    case 'PEN':
      return 'PEN'
      break
    case 'PST':
      return 'Postponed'
      break
    case 'CANC':
      return 'Cancelled'
    case 'ABD':
      return 'Abandoned'
      break
    case 'AWD':
      return 'Technical Loss'
      break
    case 'WO':
      return 'WalkOver'
      break
    default:
      return status
      break
  }
}

export const getMatchOutcomeClass = match => {
  if (getMatchStatus(match?.fixture) !== 'C') {
    return ''
  }

  const matchTeams = match.teams
  const homeTeam = matchTeams.home
  const awayTeam = matchTeams.away

  if (!homeTeam.winner && !awayTeam.winner) {
    return 't-match-drawn'
  }

  if (
    (ROVERS_TEAM_IDS.includes(homeTeam.id) && homeTeam.winner) ||
    (ROVERS_TEAM_IDS.includes(awayTeam.id) && awayTeam.winner)
  ) {
    return 't-match-won'
  } else {
    return 't-match-lost'
  }
}

export const getMatchCentreURL = (match, hash = '') => {
    if (!match) {
      return ''
    }
    const matchID = match.fixture.id;
    const homeTeamName = urlify(match.teams.home.name);
    const awayTeamName = urlify(match.teams.away.name);
    const slug = `/football/match/${matchID}/${homeTeamName}-${awayTeamName}`;

    if (hash) {
        return `${slug}#${hash}`;
    }
    return slug;
}

export const isHomeFixture = (match) => {
    if (!match) {
        return false;
    }
    const matchTeams = match.teams;
    const homeTeam = matchTeams.home
    return ROVERS_TEAM_IDS.includes(homeTeam.id) ? true : false;
}

export const getMatchEventTitle = (event) => {
    if (event.type === 'Goal') {
        switch (event.detail) {
            case 'Normal Goal':
                return `Goal!!`
                break
            case 'Own Goal':
                return 'Own Goal'
                break
            case 'Penalty':
                return 'Penalty Scored!!'
                break
            default:
                return event.detail
                break
        }
    }

    if (event.type === 'subst') {
        return 'Substitution'
    }

    return event.detail;
}

export const getMatchTime = (timeObj) => {
    let timeStr = '';

    if (timeObj.elapsed) {
        timeStr += timeObj.elapsed
    }

    if (timeObj.extra) {
        timeStr += `+${timeObj.extra}`
    }

    return `${timeStr}'`;
}

export const renderEventScore = (scoreObj) => {
    if (!scoreObj) {
        return '';
    }

    return `${scoreObj.home} - ${scoreObj.away}`;
}

export const getEventScorerString = (playerName) => {
    if (!playerName) {
        return '';
    }
    return `⚽️ ${playerName}`;
}

export const getEventAssistString = (playerName) => {
    if (!playerName) {
        return '';
    }
    return `🅰️ ${playerName}`;
}

export const renderMatchTime = (timeObj) => {
    let timeStr = '';

    if (timeObj.elapsed) {
        timeStr += timeObj.elapsed
    }

    if (timeObj.extra) {
        timeStr += `+${timeObj.extra}`
    }

    return `${timeStr}'`;
}


export const getOpponentName = (match) => {

    if (!match) {
        return '';
    }

    const matchTeams = match.teams
    const homeTeam = matchTeams.home
    const awayTeam = matchTeams.away

    if (ROVERS_TEAM_IDS.includes(homeTeam.id)) {
        return awayTeam.name
    }

    return homeTeam.name
}

export const getOpponentLogoPath = (match) => {

    if (!match) {
        return '';
    }

    const matchTeams = match.teams
    const homeTeam = matchTeams.home
    const awayTeam = matchTeams.away

    if (ROVERS_TEAM_IDS.includes(homeTeam.id)) {
        return getCDNTeamLogoPath(awayTeam.id)
    }

    return getCDNTeamLogoPath(homeTeam.id)
}
